import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import QuocGia from './quoc-gia';
import QuocGiaDetail from './quoc-gia-detail';
import QuocGiaUpdate from './quoc-gia-update';
import QuocGiaDeleteDialog from './quoc-gia-delete-dialog';

const QuocGiaRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<QuocGia />} />
    <Route path="new" element={<QuocGiaUpdate />} />
    <Route path=":id">
      <Route index element={<QuocGiaDetail />} />
      <Route path="edit" element={<QuocGiaUpdate />} />
      <Route path="delete" element={<QuocGiaDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default QuocGiaRoutes;
