import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ThietBi from './thiet-bi';
import ThietBiDetail from './thiet-bi-detail';
import ThietBiUpdate from './thiet-bi-update';
import ThietBiDeleteDialog from './thiet-bi-delete-dialog';

const ThietBiRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ThietBi />} />
    <Route path="new" element={<ThietBiUpdate />} />
    <Route path=":id">
      <Route index element={<ThietBiDetail />} />
      <Route path="edit" element={<ThietBiUpdate />} />
      <Route path="delete" element={<ThietBiDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ThietBiRoutes;
