import dayjs from 'dayjs';
import { ILoaiTaiLieu } from 'app/shared/model/loai-tai-lieu.model';
import { INhanSu } from 'app/shared/model/nhan-su.model';

export interface ITaiLieu {
  id?: number;
  tieuDe?: string | null;
  chiTiet?: string | null;
  tacGia?: string | null;
  ngayDang?: string | null;
  priority?: number | null;
  enable?: boolean | null;
  hinhAnhDaiDien?: string | null;
  fileNoiDung?: string | null;
  loaiTaiLieus?: ILoaiTaiLieu[] | null;
  nhanSu?: INhanSu | null;
}

export const defaultValue: Readonly<ITaiLieu> = {
  enable: false,
};
