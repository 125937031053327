import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LoaiTaiLieu from './loai-tai-lieu';
import LoaiTaiLieuDetail from './loai-tai-lieu-detail';
import LoaiTaiLieuUpdate from './loai-tai-lieu-update';
import LoaiTaiLieuDeleteDialog from './loai-tai-lieu-delete-dialog';

const LoaiTaiLieuRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LoaiTaiLieu />} />
    <Route path="new" element={<LoaiTaiLieuUpdate />} />
    <Route path=":id">
      <Route index element={<LoaiTaiLieuDetail />} />
      <Route path="edit" element={<LoaiTaiLieuUpdate />} />
      <Route path="delete" element={<LoaiTaiLieuDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LoaiTaiLieuRoutes;
