import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IChuThanh } from 'app/shared/model/chu-thanh.model';
import { getEntities as getChuThanhs } from 'app/entities/chu-thanh/chu-thanh.reducer';
import { ITenThanh } from 'app/shared/model/ten-thanh.model';
import { getEntities as getTenThanhs } from 'app/entities/ten-thanh/ten-thanh.reducer';
import { IMucVu } from 'app/shared/model/muc-vu.model';
import { getEntities as getMucVus } from 'app/entities/muc-vu/muc-vu.reducer';
import { ICongDoan } from 'app/shared/model/cong-doan.model';
import { getEntities as getCongDoans } from 'app/entities/cong-doan/cong-doan.reducer';
import { IQuocGia } from 'app/shared/model/quoc-gia.model';
import { getEntities as getQuocGias } from 'app/entities/quoc-gia/quoc-gia.reducer';
import { ITinhTrang } from 'app/shared/model/tinh-trang.model';
import { getEntities as getTinhTrangs } from 'app/entities/tinh-trang/tinh-trang.reducer';
import { INhanSu } from 'app/shared/model/nhan-su.model';
import { getEntity, updateEntity, createEntity, reset } from './nhan-su.reducer';

export const NhanSuUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const chuThanhs = useAppSelector(state => state.chuThanh.entities);
  const tenThanhs = useAppSelector(state => state.tenThanh.entities);
  const mucVus = useAppSelector(state => state.mucVu.entities);
  const congDoans = useAppSelector(state => state.congDoan.entities);
  const quocGias = useAppSelector(state => state.quocGia.entities);
  const tinhTrangs = useAppSelector(state => state.tinhTrang.entities);
  const nhanSuEntity = useAppSelector(state => state.nhanSu.entity);
  const loading = useAppSelector(state => state.nhanSu.loading);
  const updating = useAppSelector(state => state.nhanSu.updating);
  const updateSuccess = useAppSelector(state => state.nhanSu.updateSuccess);

  const handleClose = () => {
    navigate('/nhan-su' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
    dispatch(getChuThanhs({}));
    dispatch(getTenThanhs({}));
    dispatch(getMucVus({}));
    dispatch(getCongDoans({}));
    dispatch(getQuocGias({}));
    dispatch(getTinhTrangs({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.ngaySinh = convertDateTimeToServer(values.ngaySinh);
    values.ngayKhan = convertDateTimeToServer(values.ngayKhan);
    values.ngayPhoTe = convertDateTimeToServer(values.ngayPhoTe);
    values.ngayLinhMuc = convertDateTimeToServer(values.ngayLinhMuc);
    values.ngayChet = convertDateTimeToServer(values.ngayChet);
    values.ngayMatCuaBa = convertDateTimeToServer(values.ngayMatCuaBa);
    values.ngayMatCuaMe = convertDateTimeToServer(values.ngayMatCuaMe);

    const entity = {
      ...nhanSuEntity,
      ...values,
      user: users.find(it => it.id.toString() === values.user.toString()),
      chucThanh: chuThanhs.find(it => it.id.toString() === values.chucThanh.toString()),
      tenThanh: tenThanhs.find(it => it.id.toString() === values.tenThanh.toString()),
      tenThanhCuaBa: tenThanhs.find(it => it.id.toString() === values.tenThanhCuaBa.toString()),
      tenThanhCuaMe: tenThanhs.find(it => it.id.toString() === values.tenThanhCuaMe.toString()),
      mucVu: mucVus.find(it => it.id.toString() === values.mucVu.toString()),
      congDoan: congDoans.find(it => it.id.toString() === values.congDoan.toString()),
      quocGia: quocGias.find(it => it.id.toString() === values.quocGia.toString()),
      tinhTrang: tinhTrangs.find(it => it.id.toString() === values.tinhTrang.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          ngaySinh: displayDefaultDateTime(),
          ngayKhan: displayDefaultDateTime(),
          ngayPhoTe: displayDefaultDateTime(),
          ngayLinhMuc: displayDefaultDateTime(),
          ngayChet: displayDefaultDateTime(),
          ngayMatCuaBa: displayDefaultDateTime(),
          ngayMatCuaMe: displayDefaultDateTime(),
        }
      : {
          ...nhanSuEntity,
          ngaySinh: convertDateTimeFromServer(nhanSuEntity.ngaySinh),
          ngayKhan: convertDateTimeFromServer(nhanSuEntity.ngayKhan),
          ngayPhoTe: convertDateTimeFromServer(nhanSuEntity.ngayPhoTe),
          ngayLinhMuc: convertDateTimeFromServer(nhanSuEntity.ngayLinhMuc),
          ngayChet: convertDateTimeFromServer(nhanSuEntity.ngayChet),
          ngayMatCuaBa: convertDateTimeFromServer(nhanSuEntity.ngayMatCuaBa),
          ngayMatCuaMe: convertDateTimeFromServer(nhanSuEntity.ngayMatCuaMe),
          user: nhanSuEntity?.user?.id,
          chucThanh: nhanSuEntity?.chucThanh?.id,
          tenThanh: nhanSuEntity?.tenThanh?.id,
          mucVu: nhanSuEntity?.mucVu?.id,
          congDoan: nhanSuEntity?.congDoan?.id,
          quocGia: nhanSuEntity?.quocGia?.id,
          tinhTrang: nhanSuEntity?.tinhTrang?.id,
          tenThanhCuaBa: nhanSuEntity?.tenThanhCuaBa?.id,
          tenThanhCuaMe: nhanSuEntity?.tenThanhCuaMe?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dcctApiApp.nhanSu.home.createOrEditLabel" data-cy="NhanSuCreateUpdateHeading">
            <Translate contentKey="dcctApiApp.nhanSu.home.createOrEditLabel">Create or edit a NhanSu</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="nhan-su-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ma')}
                id="nhan-su-ma"
                name="ma"
                data-cy="ma"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.avatar')}
                id="nhan-su-avatar"
                name="avatar"
                data-cy="avatar"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.diaChi')}
                id="nhan-su-diaChi"
                name="diaChi"
                data-cy="diaChi"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.dienThoai')}
                id="nhan-su-dienThoai"
                name="dienThoai"
                data-cy="dienThoai"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ghiChu')}
                id="nhan-su-ghiChu"
                name="ghiChu"
                data-cy="ghiChu"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ngaySinh')}
                id="nhan-su-ngaySinh"
                name="ngaySinh"
                data-cy="ngaySinh"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ngayKhan')}
                id="nhan-su-ngayKhan"
                name="ngayKhan"
                data-cy="ngayKhan"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ngayPhoTe')}
                id="nhan-su-ngayPhoTe"
                name="ngayPhoTe"
                data-cy="ngayPhoTe"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ngayLinhMuc')}
                id="nhan-su-ngayLinhMuc"
                name="ngayLinhMuc"
                data-cy="ngayLinhMuc"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ngayChet')}
                id="nhan-su-ngayChet"
                name="ngayChet"
                data-cy="ngayChet"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.hoTenBa')}
                id="nhan-su-hoTenBa"
                name="hoTenBa"
                data-cy="hoTenBa"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ngayMatCuaBa')}
                id="nhan-su-ngayMatCuaBa"
                name="ngayMatCuaBa"
                data-cy="ngayMatCuaBa"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.hoTenMe')}
                id="nhan-su-hoTenMe"
                name="hoTenMe"
                data-cy="hoTenMe"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.ngayMatCuaMe')}
                id="nhan-su-ngayMatCuaMe"
                name="ngayMatCuaMe"
                data-cy="ngayMatCuaMe"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.otpResetMatKhau')}
                id="nhan-su-otpResetMatKhau"
                name="otpResetMatKhau"
                data-cy="otpResetMatKhau"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.nhanSu.chatId')}
                id="nhan-su-chatId"
                name="chatId"
                data-cy="chatId"
                type="text"
                validate={{
                  maxLength: { value: 3000, message: translate('entity.validation.maxlength', { max: 3000 }) },
                }}
              />
              <ValidatedField
                id="nhan-su-user"
                name="user"
                data-cy="user"
                label={translate('dcctApiApp.nhanSu.user')}
                type="select"
                required
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.login}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <ValidatedField
                id="nhan-su-chucThanh"
                name="chucThanh"
                data-cy="chucThanh"
                label={translate('dcctApiApp.nhanSu.chucThanh')}
                type="select"
              >
                <option value="" key="0" />
                {chuThanhs
                  ? chuThanhs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="nhan-su-tenThanh"
                name="tenThanh"
                data-cy="tenThanh"
                label={translate('dcctApiApp.nhanSu.tenThanh')}
                type="select"
              >
                <option value="" key="0" />
                {tenThanhs
                  ? tenThanhs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="nhan-su-mucVu" name="mucVu" data-cy="mucVu" label={translate('dcctApiApp.nhanSu.mucVu')} type="select">
                <option value="" key="0" />
                {mucVus
                  ? mucVus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="nhan-su-congDoan"
                name="congDoan"
                data-cy="congDoan"
                label={translate('dcctApiApp.nhanSu.congDoan')}
                type="select"
              >
                <option value="" key="0" />
                {congDoans
                  ? congDoans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="nhan-su-quocGia"
                name="quocGia"
                data-cy="quocGia"
                label={translate('dcctApiApp.nhanSu.quocGia')}
                type="select"
              >
                <option value="" key="0" />
                {quocGias
                  ? quocGias.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="nhan-su-tinhTrang"
                name="tinhTrang"
                data-cy="tinhTrang"
                label={translate('dcctApiApp.nhanSu.tinhTrang')}
                type="select"
              >
                <option value="" key="0" />
                {tinhTrangs
                  ? tinhTrangs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="nhan-su-tenThanhCuaBa"
                name="tenThanhCuaBa"
                data-cy="tenThanhCuaBa"
                label={translate('dcctApiApp.nhanSu.tenThanhCuaBa')}
                type="select"
              >
                <option value="" key="0" />
                {tenThanhs
                  ? tenThanhs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="nhan-su-tenThanhCuaMe"
                name="tenThanhCuaMe"
                data-cy="tenThanhCuaMe"
                label={translate('dcctApiApp.nhanSu.tenThanhCuaMe')}
                type="select"
              >
                <option value="" key="0" />
                {tenThanhs
                  ? tenThanhs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/nhan-su" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NhanSuUpdate;
