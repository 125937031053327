import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITinTuc } from 'app/shared/model/tin-tuc.model';
import { getEntities as getTinTucs } from 'app/entities/tin-tuc/tin-tuc.reducer';
import { INhanSu } from 'app/shared/model/nhan-su.model';
import { getEntities as getNhanSus } from 'app/entities/nhan-su/nhan-su.reducer';
import { IThuChaGiamTinh } from 'app/shared/model/thu-cha-giam-tinh.model';
import { getEntity, updateEntity, createEntity, reset } from './thu-cha-giam-tinh.reducer';

export const ThuChaGiamTinhUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tinTucs = useAppSelector(state => state.tinTuc.entities);
  const nhanSus = useAppSelector(state => state.nhanSu.entities);
  const thuChaGiamTinhEntity = useAppSelector(state => state.thuChaGiamTinh.entity);
  const loading = useAppSelector(state => state.thuChaGiamTinh.loading);
  const updating = useAppSelector(state => state.thuChaGiamTinh.updating);
  const updateSuccess = useAppSelector(state => state.thuChaGiamTinh.updateSuccess);

  const handleClose = () => {
    navigate('/thu-cha-giam-tinh' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getTinTucs({}));
    dispatch(getNhanSus({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...thuChaGiamTinhEntity,
      ...values,
      nhanSuDang: nhanSus.find(it => it.id.toString() === values.nhanSuDang.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...thuChaGiamTinhEntity,
          nhanSuDang: thuChaGiamTinhEntity?.nhanSuDang?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dcctApiApp.thuChaGiamTinh.home.createOrEditLabel" data-cy="ThuChaGiamTinhCreateUpdateHeading">
            <Translate contentKey="dcctApiApp.thuChaGiamTinh.home.createOrEditLabel">Create or edit a ThuChaGiamTinh</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="thu-cha-giam-tinh-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dcctApiApp.thuChaGiamTinh.thangNam')}
                id="thu-cha-giam-tinh-thangNam"
                name="thangNam"
                data-cy="thangNam"
                type="text"
                validate={{
                  maxLength: { value: 50, message: translate('entity.validation.maxlength', { max: 50 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.thuChaGiamTinh.so')}
                id="thu-cha-giam-tinh-so"
                name="so"
                data-cy="so"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.thuChaGiamTinh.tieuDe')}
                id="thu-cha-giam-tinh-tieuDe"
                name="tieuDe"
                data-cy="tieuDe"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.thuChaGiamTinh.preview')}
                id="thu-cha-giam-tinh-preview"
                name="preview"
                data-cy="preview"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.thuChaGiamTinh.detail')}
                id="thu-cha-giam-tinh-detail"
                name="detail"
                data-cy="detail"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.thuChaGiamTinh.priority')}
                id="thu-cha-giam-tinh-priority"
                name="priority"
                data-cy="priority"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.thuChaGiamTinh.hinhAnhDaiDien')}
                id="thu-cha-giam-tinh-hinhAnhDaiDien"
                name="hinhAnhDaiDien"
                data-cy="hinhAnhDaiDien"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.thuChaGiamTinh.fileNoiDung')}
                id="thu-cha-giam-tinh-fileNoiDung"
                name="fileNoiDung"
                data-cy="fileNoiDung"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                id="thu-cha-giam-tinh-nhanSuDang"
                name="nhanSuDang"
                data-cy="nhanSuDang"
                label={translate('dcctApiApp.thuChaGiamTinh.nhanSuDang')}
                type="select"
              >
                <option value="" key="0" />
                {nhanSus
                  ? nhanSus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/thu-cha-giam-tinh" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ThuChaGiamTinhUpdate;
