import dayjs from 'dayjs';
import { ICongDoan } from 'app/shared/model/cong-doan.model';
import { INhanSu } from 'app/shared/model/nhan-su.model';
import { LoaiSuKienEnum } from 'app/shared/model/enumerations/loai-su-kien-enum.model';

export interface ISuKien {
  id?: number;
  trongNgay?: string | null;
  tuGio?: string | null;
  denGio?: string | null;
  tenSuKien?: string | null;
  chiTietSuKien?: string | null;
  loaiSuKien?: LoaiSuKienEnum | null;
  denNgay?: string | null;
  congDoans?: ICongDoan[] | null;
  nhanSus?: INhanSu[] | null;
  nhanSuDangBai?: INhanSu | null;
}

export const defaultValue: Readonly<ISuKien> = {};
