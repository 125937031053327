import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IChuThanh } from 'app/shared/model/chu-thanh.model';
import { ITenThanh } from 'app/shared/model/ten-thanh.model';
import { IMucVu } from 'app/shared/model/muc-vu.model';
import { ICongDoan } from 'app/shared/model/cong-doan.model';
import { IQuocGia } from 'app/shared/model/quoc-gia.model';
import { ITinhTrang } from 'app/shared/model/tinh-trang.model';
import { IThietBi } from 'app/shared/model/thiet-bi.model';

export interface INhanSu {
  id?: number;
  ma?: string | null;
  avatar?: string | null;
  diaChi?: string | null;
  dienThoai?: string | null;
  ghiChu?: string | null;
  ngaySinh?: string | null;
  ngayKhan?: string | null;
  ngayPhoTe?: string | null;
  ngayLinhMuc?: string | null;
  ngayChet?: string | null;
  hoTenBa?: string | null;
  ngayMatCuaBa?: string | null;
  hoTenMe?: string | null;
  ngayMatCuaMe?: string | null;
  otpResetMatKhau?: string | null;
  chatId?: string | null;
  user?: IUser;
  chucThanh?: IChuThanh | null;
  tenThanh?: ITenThanh | null;
  mucVu?: IMucVu | null;
  congDoan?: ICongDoan | null;
  quocGia?: IQuocGia | null;
  tinhTrang?: ITinhTrang | null;
  tenThanhCuaBa?: ITenThanh | null;
  tenThanhCuaMe?: ITenThanh | null;
  thietBis?: IThietBi[] | null;
}

export const defaultValue: Readonly<INhanSu> = {};
