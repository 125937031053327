import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ITenThanh } from 'app/shared/model/ten-thanh.model';
import { GioiTinhEnum } from 'app/shared/model/enumerations/gioi-tinh-enum.model';
import { getEntity, updateEntity, createEntity, reset } from './ten-thanh.reducer';

export const TenThanhUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tenThanhEntity = useAppSelector(state => state.tenThanh.entity);
  const loading = useAppSelector(state => state.tenThanh.loading);
  const updating = useAppSelector(state => state.tenThanh.updating);
  const updateSuccess = useAppSelector(state => state.tenThanh.updateSuccess);
  const gioiTinhEnumValues = Object.keys(GioiTinhEnum);

  const handleClose = () => {
    navigate('/ten-thanh' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...tenThanhEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          gioiTinh: 'NAM',
          ...tenThanhEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dcctApiApp.tenThanh.home.createOrEditLabel" data-cy="TenThanhCreateUpdateHeading">
            <Translate contentKey="dcctApiApp.tenThanh.home.createOrEditLabel">Create or edit a TenThanh</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="ten-thanh-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dcctApiApp.tenThanh.tenThanh')}
                id="ten-thanh-tenThanh"
                name="tenThanh"
                data-cy="tenThanh"
                type="text"
                validate={{
                  maxLength: { value: 255, message: translate('entity.validation.maxlength', { max: 255 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.tenThanh.ngayKinh')}
                id="ten-thanh-ngayKinh"
                name="ngayKinh"
                data-cy="ngayKinh"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.tenThanh.gioiTinh')}
                id="ten-thanh-gioiTinh"
                name="gioiTinh"
                data-cy="gioiTinh"
                type="select"
              >
                {gioiTinhEnumValues.map(gioiTinhEnum => (
                  <option value={gioiTinhEnum} key={gioiTinhEnum}>
                    {translate('dcctApiApp.GioiTinhEnum.' + gioiTinhEnum)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('dcctApiApp.tenThanh.hinhAnhViThanh')}
                id="ten-thanh-hinhAnhViThanh"
                name="hinhAnhViThanh"
                data-cy="hinhAnhViThanh"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.tenThanh.priority')}
                id="ten-thanh-priority"
                name="priority"
                data-cy="priority"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/ten-thanh" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TenThanhUpdate;
