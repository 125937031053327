import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './cong-doan.reducer';

export const CongDoanDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const congDoanEntity = useAppSelector(state => state.congDoan.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="congDoanDetailsHeading">
          <Translate contentKey="dcctApiApp.congDoan.detail.title">CongDoan</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{congDoanEntity.id}</dd>
          <dt>
            <span id="tenCongDoan">
              <Translate contentKey="dcctApiApp.congDoan.tenCongDoan">Ten Cong Doan</Translate>
            </span>
          </dt>
          <dd>{congDoanEntity.tenCongDoan}</dd>
          <dt>
            <span id="ngayThanhLap">
              <Translate contentKey="dcctApiApp.congDoan.ngayThanhLap">Ngay Thanh Lap</Translate>
            </span>
          </dt>
          <dd>
            {congDoanEntity.ngayThanhLap ? <TextFormat value={congDoanEntity.ngayThanhLap} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="ngayBonMang">
              <Translate contentKey="dcctApiApp.congDoan.ngayBonMang">Ngay Bon Mang</Translate>
            </span>
          </dt>
          <dd>
            {congDoanEntity.ngayBonMang ? <TextFormat value={congDoanEntity.ngayBonMang} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="tenThanhBon">
              <Translate contentKey="dcctApiApp.congDoan.tenThanhBon">Ten Thanh Bon</Translate>
            </span>
          </dt>
          <dd>{congDoanEntity.tenThanhBon}</dd>
          <dt>
            <span id="diaChi">
              <Translate contentKey="dcctApiApp.congDoan.diaChi">Dia Chi</Translate>
            </span>
          </dt>
          <dd>{congDoanEntity.diaChi}</dd>
          <dt>
            <span id="hinhAnhDaiDien">
              <Translate contentKey="dcctApiApp.congDoan.hinhAnhDaiDien">Hinh Anh Dai Dien</Translate>
            </span>
          </dt>
          <dd>{congDoanEntity.hinhAnhDaiDien}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="dcctApiApp.congDoan.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{congDoanEntity.priority}</dd>
          <dt>
            <span id="nhomChatUid">
              <Translate contentKey="dcctApiApp.congDoan.nhomChatUid">Nhom Chat Uid</Translate>
            </span>
          </dt>
          <dd>{congDoanEntity.nhomChatUid}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.congDoan.vung">Vung</Translate>
          </dt>
          <dd>{congDoanEntity.vung ? congDoanEntity.vung.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.congDoan.loaiCongDoan">Loai Cong Doan</Translate>
          </dt>
          <dd>{congDoanEntity.loaiCongDoan ? congDoanEntity.loaiCongDoan.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.congDoan.chaBeTren">Cha Be Tren</Translate>
          </dt>
          <dd>{congDoanEntity.chaBeTren ? congDoanEntity.chaBeTren.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/cong-doan" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/cong-doan/${congDoanEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default CongDoanDetail;
