import { ITinTuc } from 'app/shared/model/tin-tuc.model';
import { INhanSu } from 'app/shared/model/nhan-su.model';

export interface IThuChaGiamTinh {
  id?: number;
  thangNam?: string | null;
  so?: number | null;
  tieuDe?: string | null;
  preview?: string | null;
  detail?: string | null;
  priority?: number | null;
  hinhAnhDaiDien?: string | null;
  fileNoiDung?: string | null;
  tinTucs?: ITinTuc[] | null;
  nhanSuDang?: INhanSu | null;
}

export const defaultValue: Readonly<IThuChaGiamTinh> = {};
