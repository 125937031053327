import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NhanSu from './nhan-su';
import ChuThanh from './chu-thanh';
import TenThanh from './ten-thanh';
import MucVu from './muc-vu';
import CongDoan from './cong-doan';
import Vung from './vung';
import LoaiCongDoan from './loai-cong-doan';
import QuocGia from './quoc-gia';
import TinhTrang from './tinh-trang';
import ThietBi from './thiet-bi';
import LoaiTinTuc from './loai-tin-tuc';
import ThuChaGiamTinh from './thu-cha-giam-tinh';
import TinTuc from './tin-tuc';
import LinhDong from './linh-dong';
import SuKien from './su-kien';
import LoaiTaiLieu from './loai-tai-lieu';
import TaiLieu from './tai-lieu';
import ThongBao from './thong-bao';
import PhanQuyenUser from './phan-quyen-user';
import ProjectSetting from './project-setting';
import HinhAnhTinTuc from './hinh-anh-tin-tuc';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="nhan-su/*" element={<NhanSu />} />
        <Route path="chu-thanh/*" element={<ChuThanh />} />
        <Route path="ten-thanh/*" element={<TenThanh />} />
        <Route path="muc-vu/*" element={<MucVu />} />
        <Route path="cong-doan/*" element={<CongDoan />} />
        <Route path="vung/*" element={<Vung />} />
        <Route path="loai-cong-doan/*" element={<LoaiCongDoan />} />
        <Route path="quoc-gia/*" element={<QuocGia />} />
        <Route path="tinh-trang/*" element={<TinhTrang />} />
        <Route path="thiet-bi/*" element={<ThietBi />} />
        <Route path="loai-tin-tuc/*" element={<LoaiTinTuc />} />
        <Route path="thu-cha-giam-tinh/*" element={<ThuChaGiamTinh />} />
        <Route path="tin-tuc/*" element={<TinTuc />} />
        <Route path="linh-dong/*" element={<LinhDong />} />
        <Route path="su-kien/*" element={<SuKien />} />
        <Route path="loai-tai-lieu/*" element={<LoaiTaiLieu />} />
        <Route path="tai-lieu/*" element={<TaiLieu />} />
        <Route path="thong-bao/*" element={<ThongBao />} />
        <Route path="phan-quyen-user/*" element={<PhanQuyenUser />} />
        <Route path="project-setting/*" element={<ProjectSetting />} />
        <Route path="hinh-anh-tin-tuc/*" element={<HinhAnhTinTuc />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
