import { INhanSu } from 'app/shared/model/nhan-su.model';

export interface IThietBi {
  id?: number;
  maThietBi?: string | null;
  firebaseId?: string | null;
  tenThietBi?: string | null;
  trangThai?: boolean | null;
  jwt?: string | null;
  nhanSu?: INhanSu | null;
}

export const defaultValue: Readonly<IThietBi> = {
  trangThai: false,
};
