import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './thu-cha-giam-tinh.reducer';

export const ThuChaGiamTinhDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const thuChaGiamTinhEntity = useAppSelector(state => state.thuChaGiamTinh.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="thuChaGiamTinhDetailsHeading">
          <Translate contentKey="dcctApiApp.thuChaGiamTinh.detail.title">ThuChaGiamTinh</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.id}</dd>
          <dt>
            <span id="thangNam">
              <Translate contentKey="dcctApiApp.thuChaGiamTinh.thangNam">Thang Nam</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.thangNam}</dd>
          <dt>
            <span id="so">
              <Translate contentKey="dcctApiApp.thuChaGiamTinh.so">So</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.so}</dd>
          <dt>
            <span id="tieuDe">
              <Translate contentKey="dcctApiApp.thuChaGiamTinh.tieuDe">Tieu De</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.tieuDe}</dd>
          <dt>
            <span id="preview">
              <Translate contentKey="dcctApiApp.thuChaGiamTinh.preview">Preview</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.preview}</dd>
          <dt>
            <span id="detail">
              <Translate contentKey="dcctApiApp.thuChaGiamTinh.detail">Detail</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.detail}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="dcctApiApp.thuChaGiamTinh.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.priority}</dd>
          <dt>
            <span id="hinhAnhDaiDien">
              <Translate contentKey="dcctApiApp.thuChaGiamTinh.hinhAnhDaiDien">Hinh Anh Dai Dien</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.hinhAnhDaiDien}</dd>
          <dt>
            <span id="fileNoiDung">
              <Translate contentKey="dcctApiApp.thuChaGiamTinh.fileNoiDung">File Noi Dung</Translate>
            </span>
          </dt>
          <dd>{thuChaGiamTinhEntity.fileNoiDung}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.thuChaGiamTinh.nhanSuDang">Nhan Su Dang</Translate>
          </dt>
          <dd>{thuChaGiamTinhEntity.nhanSuDang ? thuChaGiamTinhEntity.nhanSuDang.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/thu-cha-giam-tinh" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/thu-cha-giam-tinh/${thuChaGiamTinhEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ThuChaGiamTinhDetail;
