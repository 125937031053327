import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './su-kien.reducer';

export const SuKienDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const suKienEntity = useAppSelector(state => state.suKien.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="suKienDetailsHeading">
          <Translate contentKey="dcctApiApp.suKien.detail.title">SuKien</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{suKienEntity.id}</dd>
          <dt>
            <span id="trongNgay">
              <Translate contentKey="dcctApiApp.suKien.trongNgay">Trong Ngay</Translate>
            </span>
          </dt>
          <dd>{suKienEntity.trongNgay ? <TextFormat value={suKienEntity.trongNgay} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="tuGio">
              <Translate contentKey="dcctApiApp.suKien.tuGio">Tu Gio</Translate>
            </span>
          </dt>
          <dd>{suKienEntity.tuGio}</dd>
          <dt>
            <span id="denGio">
              <Translate contentKey="dcctApiApp.suKien.denGio">Den Gio</Translate>
            </span>
          </dt>
          <dd>{suKienEntity.denGio}</dd>
          <dt>
            <span id="tenSuKien">
              <Translate contentKey="dcctApiApp.suKien.tenSuKien">Ten Su Kien</Translate>
            </span>
          </dt>
          <dd>{suKienEntity.tenSuKien}</dd>
          <dt>
            <span id="chiTietSuKien">
              <Translate contentKey="dcctApiApp.suKien.chiTietSuKien">Chi Tiet Su Kien</Translate>
            </span>
          </dt>
          <dd>{suKienEntity.chiTietSuKien}</dd>
          <dt>
            <span id="loaiSuKien">
              <Translate contentKey="dcctApiApp.suKien.loaiSuKien">Loai Su Kien</Translate>
            </span>
          </dt>
          <dd>{suKienEntity.loaiSuKien}</dd>
          <dt>
            <span id="denNgay">
              <Translate contentKey="dcctApiApp.suKien.denNgay">Den Ngay</Translate>
            </span>
          </dt>
          <dd>{suKienEntity.denNgay ? <TextFormat value={suKienEntity.denNgay} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.suKien.congDoan">Cong Doan</Translate>
          </dt>
          <dd>
            {suKienEntity.congDoans
              ? suKienEntity.congDoans.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.tenCongDoan}</a>
                    {suKienEntity.congDoans && i === suKienEntity.congDoans.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="dcctApiApp.suKien.nhanSu">Nhan Su</Translate>
          </dt>
          <dd>
            {suKienEntity.nhanSus
              ? suKienEntity.nhanSus.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {suKienEntity.nhanSus && i === suKienEntity.nhanSus.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="dcctApiApp.suKien.nhanSuDangBai">Nhan Su Dang Bai</Translate>
          </dt>
          <dd>{suKienEntity.nhanSuDangBai ? suKienEntity.nhanSuDangBai.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/su-kien" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/su-kien/${suKienEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SuKienDetail;
