import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IVung } from 'app/shared/model/vung.model';
import { getEntities as getVungs } from 'app/entities/vung/vung.reducer';
import { ILoaiCongDoan } from 'app/shared/model/loai-cong-doan.model';
import { getEntities as getLoaiCongDoans } from 'app/entities/loai-cong-doan/loai-cong-doan.reducer';
import { INhanSu } from 'app/shared/model/nhan-su.model';
import { getEntities as getNhanSus } from 'app/entities/nhan-su/nhan-su.reducer';
import { ITinTuc } from 'app/shared/model/tin-tuc.model';
import { getEntities as getTinTucs } from 'app/entities/tin-tuc/tin-tuc.reducer';
import { ICongDoan } from 'app/shared/model/cong-doan.model';
import { getEntity, updateEntity, createEntity, reset } from './cong-doan.reducer';

export const CongDoanUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const vungs = useAppSelector(state => state.vung.entities);
  const loaiCongDoans = useAppSelector(state => state.loaiCongDoan.entities);
  const nhanSus = useAppSelector(state => state.nhanSu.entities);
  const tinTucs = useAppSelector(state => state.tinTuc.entities);
  const congDoanEntity = useAppSelector(state => state.congDoan.entity);
  const loading = useAppSelector(state => state.congDoan.loading);
  const updating = useAppSelector(state => state.congDoan.updating);
  const updateSuccess = useAppSelector(state => state.congDoan.updateSuccess);

  const handleClose = () => {
    navigate('/cong-doan' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getVungs({}));
    dispatch(getLoaiCongDoans({}));
    dispatch(getNhanSus({}));
    dispatch(getTinTucs({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.ngayThanhLap = convertDateTimeToServer(values.ngayThanhLap);
    values.ngayBonMang = convertDateTimeToServer(values.ngayBonMang);

    const entity = {
      ...congDoanEntity,
      ...values,
      vung: vungs.find(it => it.id.toString() === values.vung.toString()),
      loaiCongDoan: loaiCongDoans.find(it => it.id.toString() === values.loaiCongDoan.toString()),
      chaBeTren: nhanSus.find(it => it.id.toString() === values.chaBeTren.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          ngayThanhLap: displayDefaultDateTime(),
          ngayBonMang: displayDefaultDateTime(),
        }
      : {
          ...congDoanEntity,
          ngayThanhLap: convertDateTimeFromServer(congDoanEntity.ngayThanhLap),
          ngayBonMang: convertDateTimeFromServer(congDoanEntity.ngayBonMang),
          vung: congDoanEntity?.vung?.id,
          loaiCongDoan: congDoanEntity?.loaiCongDoan?.id,
          chaBeTren: congDoanEntity?.chaBeTren?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dcctApiApp.congDoan.home.createOrEditLabel" data-cy="CongDoanCreateUpdateHeading">
            <Translate contentKey="dcctApiApp.congDoan.home.createOrEditLabel">Create or edit a CongDoan</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="cong-doan-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dcctApiApp.congDoan.tenCongDoan')}
                id="cong-doan-tenCongDoan"
                name="tenCongDoan"
                data-cy="tenCongDoan"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.congDoan.ngayThanhLap')}
                id="cong-doan-ngayThanhLap"
                name="ngayThanhLap"
                data-cy="ngayThanhLap"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.congDoan.ngayBonMang')}
                id="cong-doan-ngayBonMang"
                name="ngayBonMang"
                data-cy="ngayBonMang"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.congDoan.tenThanhBon')}
                id="cong-doan-tenThanhBon"
                name="tenThanhBon"
                data-cy="tenThanhBon"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.congDoan.diaChi')}
                id="cong-doan-diaChi"
                name="diaChi"
                data-cy="diaChi"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.congDoan.hinhAnhDaiDien')}
                id="cong-doan-hinhAnhDaiDien"
                name="hinhAnhDaiDien"
                data-cy="hinhAnhDaiDien"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.congDoan.priority')}
                id="cong-doan-priority"
                name="priority"
                data-cy="priority"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.congDoan.nhomChatUid')}
                id="cong-doan-nhomChatUid"
                name="nhomChatUid"
                data-cy="nhomChatUid"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField id="cong-doan-vung" name="vung" data-cy="vung" label={translate('dcctApiApp.congDoan.vung')} type="select">
                <option value="" key="0" />
                {vungs
                  ? vungs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="cong-doan-loaiCongDoan"
                name="loaiCongDoan"
                data-cy="loaiCongDoan"
                label={translate('dcctApiApp.congDoan.loaiCongDoan')}
                type="select"
              >
                <option value="" key="0" />
                {loaiCongDoans
                  ? loaiCongDoans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="cong-doan-chaBeTren"
                name="chaBeTren"
                data-cy="chaBeTren"
                label={translate('dcctApiApp.congDoan.chaBeTren')}
                type="select"
              >
                <option value="" key="0" />
                {nhanSus
                  ? nhanSus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/cong-doan" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CongDoanUpdate;
