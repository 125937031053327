import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './loai-tai-lieu.reducer';

export const LoaiTaiLieuDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const loaiTaiLieuEntity = useAppSelector(state => state.loaiTaiLieu.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="loaiTaiLieuDetailsHeading">
          <Translate contentKey="dcctApiApp.loaiTaiLieu.detail.title">LoaiTaiLieu</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{loaiTaiLieuEntity.id}</dd>
          <dt>
            <span id="tenLoaiTaiLieu">
              <Translate contentKey="dcctApiApp.loaiTaiLieu.tenLoaiTaiLieu">Ten Loai Tai Lieu</Translate>
            </span>
          </dt>
          <dd>{loaiTaiLieuEntity.tenLoaiTaiLieu}</dd>
          <dt>
            <span id="hinhAnhDaiDien">
              <Translate contentKey="dcctApiApp.loaiTaiLieu.hinhAnhDaiDien">Hinh Anh Dai Dien</Translate>
            </span>
          </dt>
          <dd>{loaiTaiLieuEntity.hinhAnhDaiDien}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="dcctApiApp.loaiTaiLieu.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{loaiTaiLieuEntity.priority}</dd>
        </dl>
        <Button tag={Link} to="/loai-tai-lieu" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/loai-tai-lieu/${loaiTaiLieuEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LoaiTaiLieuDetail;
