import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './linh-dong.reducer';

export const LinhDongDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const linhDongEntity = useAppSelector(state => state.linhDong.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="linhDongDetailsHeading">
          <Translate contentKey="dcctApiApp.linhDong.detail.title">LinhDong</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.id}</dd>
          <dt>
            <span id="ngay">
              <Translate contentKey="dcctApiApp.linhDong.ngay">Ngay</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.ngay ? <TextFormat value={linhDongEntity.ngay} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="tieuDe">
              <Translate contentKey="dcctApiApp.linhDong.tieuDe">Tieu De</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.tieuDe}</dd>
          <dt>
            <span id="tieuDePhu">
              <Translate contentKey="dcctApiApp.linhDong.tieuDePhu">Tieu De Phu</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.tieuDePhu}</dd>
          <dt>
            <span id="mauAoLe">
              <Translate contentKey="dcctApiApp.linhDong.mauAoLe">Mau Ao Le</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.mauAoLe}</dd>
          <dt>
            <span id="ghiChu">
              <Translate contentKey="dcctApiApp.linhDong.ghiChu">Ghi Chu</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.ghiChu}</dd>
          <dt>
            <span id="kinhThanh">
              <Translate contentKey="dcctApiApp.linhDong.kinhThanh">Kinh Thanh</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinhThanh}</dd>
          <dt>
            <span id="kinhSach">
              <Translate contentKey="dcctApiApp.linhDong.kinhSach">Kinh Sach</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinhSach}</dd>
          <dt>
            <span id="kinhSang">
              <Translate contentKey="dcctApiApp.linhDong.kinhSang">Kinh Sang</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinhSang}</dd>
          <dt>
            <span id="kinhTrua">
              <Translate contentKey="dcctApiApp.linhDong.kinhTrua">Kinh Trua</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinhTrua}</dd>
          <dt>
            <span id="kinhChieu">
              <Translate contentKey="dcctApiApp.linhDong.kinhChieu">Kinh Chieu</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinhChieu}</dd>
          <dt>
            <span id="kinhToi">
              <Translate contentKey="dcctApiApp.linhDong.kinhToi">Kinh Toi</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinhToi}</dd>
          <dt>
            <span id="hinhDaiDien">
              <Translate contentKey="dcctApiApp.linhDong.hinhDaiDien">Hinh Dai Dien</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.hinhDaiDien}</dd>
          <dt>
            <span id="kinh3h">
              <Translate contentKey="dcctApiApp.linhDong.kinh3h">Kinh 3 H</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinh3h}</dd>
          <dt>
            <span id="kinh6h">
              <Translate contentKey="dcctApiApp.linhDong.kinh6h">Kinh 6 H</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinh6h}</dd>
          <dt>
            <span id="kinh9h">
              <Translate contentKey="dcctApiApp.linhDong.kinh9h">Kinh 9 H</Translate>
            </span>
          </dt>
          <dd>{linhDongEntity.kinh9h}</dd>
        </dl>
        <Button tag={Link} to="/linh-dong" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/linh-dong/${linhDongEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default LinhDongDetail;
