import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ILinhDong } from 'app/shared/model/linh-dong.model';
import { getEntity, updateEntity, createEntity, reset } from './linh-dong.reducer';

export const LinhDongUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const linhDongEntity = useAppSelector(state => state.linhDong.entity);
  const loading = useAppSelector(state => state.linhDong.loading);
  const updating = useAppSelector(state => state.linhDong.updating);
  const updateSuccess = useAppSelector(state => state.linhDong.updateSuccess);

  const handleClose = () => {
    navigate('/linh-dong' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.ngay = convertDateTimeToServer(values.ngay);

    const entity = {
      ...linhDongEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          ngay: displayDefaultDateTime(),
        }
      : {
          ...linhDongEntity,
          ngay: convertDateTimeFromServer(linhDongEntity.ngay),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dcctApiApp.linhDong.home.createOrEditLabel" data-cy="LinhDongCreateUpdateHeading">
            <Translate contentKey="dcctApiApp.linhDong.home.createOrEditLabel">Create or edit a LinhDong</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="linh-dong-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dcctApiApp.linhDong.ngay')}
                id="linh-dong-ngay"
                name="ngay"
                data-cy="ngay"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.tieuDe')}
                id="linh-dong-tieuDe"
                name="tieuDe"
                data-cy="tieuDe"
                type="text"
                validate={{
                  maxLength: { value: 2000, message: translate('entity.validation.maxlength', { max: 2000 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.tieuDePhu')}
                id="linh-dong-tieuDePhu"
                name="tieuDePhu"
                data-cy="tieuDePhu"
                type="text"
                validate={{
                  maxLength: { value: 2000, message: translate('entity.validation.maxlength', { max: 2000 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.mauAoLe')}
                id="linh-dong-mauAoLe"
                name="mauAoLe"
                data-cy="mauAoLe"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.ghiChu')}
                id="linh-dong-ghiChu"
                name="ghiChu"
                data-cy="ghiChu"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinhThanh')}
                id="linh-dong-kinhThanh"
                name="kinhThanh"
                data-cy="kinhThanh"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinhSach')}
                id="linh-dong-kinhSach"
                name="kinhSach"
                data-cy="kinhSach"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinhSang')}
                id="linh-dong-kinhSang"
                name="kinhSang"
                data-cy="kinhSang"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinhTrua')}
                id="linh-dong-kinhTrua"
                name="kinhTrua"
                data-cy="kinhTrua"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinhChieu')}
                id="linh-dong-kinhChieu"
                name="kinhChieu"
                data-cy="kinhChieu"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinhToi')}
                id="linh-dong-kinhToi"
                name="kinhToi"
                data-cy="kinhToi"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.hinhDaiDien')}
                id="linh-dong-hinhDaiDien"
                name="hinhDaiDien"
                data-cy="hinhDaiDien"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinh3h')}
                id="linh-dong-kinh3h"
                name="kinh3h"
                data-cy="kinh3h"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinh6h')}
                id="linh-dong-kinh6h"
                name="kinh6h"
                data-cy="kinh6h"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.linhDong.kinh9h')}
                id="linh-dong-kinh9h"
                name="kinh9h"
                data-cy="kinh9h"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/linh-dong" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default LinhDongUpdate;
