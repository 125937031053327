import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ILoaiTaiLieu } from 'app/shared/model/loai-tai-lieu.model';
import { getEntities as getLoaiTaiLieus } from 'app/entities/loai-tai-lieu/loai-tai-lieu.reducer';
import { INhanSu } from 'app/shared/model/nhan-su.model';
import { getEntities as getNhanSus } from 'app/entities/nhan-su/nhan-su.reducer';
import { ITaiLieu } from 'app/shared/model/tai-lieu.model';
import { getEntity, updateEntity, createEntity, reset } from './tai-lieu.reducer';

export const TaiLieuUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const loaiTaiLieus = useAppSelector(state => state.loaiTaiLieu.entities);
  const nhanSus = useAppSelector(state => state.nhanSu.entities);
  const taiLieuEntity = useAppSelector(state => state.taiLieu.entity);
  const loading = useAppSelector(state => state.taiLieu.loading);
  const updating = useAppSelector(state => state.taiLieu.updating);
  const updateSuccess = useAppSelector(state => state.taiLieu.updateSuccess);

  const handleClose = () => {
    navigate('/tai-lieu' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getLoaiTaiLieus({}));
    dispatch(getNhanSus({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.ngayDang = convertDateTimeToServer(values.ngayDang);

    const entity = {
      ...taiLieuEntity,
      ...values,
      loaiTaiLieus: mapIdList(values.loaiTaiLieus),
      nhanSu: nhanSus.find(it => it.id.toString() === values.nhanSu.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          ngayDang: displayDefaultDateTime(),
        }
      : {
          ...taiLieuEntity,
          ngayDang: convertDateTimeFromServer(taiLieuEntity.ngayDang),
          loaiTaiLieus: taiLieuEntity?.loaiTaiLieus?.map(e => e.id.toString()),
          nhanSu: taiLieuEntity?.nhanSu?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dcctApiApp.taiLieu.home.createOrEditLabel" data-cy="TaiLieuCreateUpdateHeading">
            <Translate contentKey="dcctApiApp.taiLieu.home.createOrEditLabel">Create or edit a TaiLieu</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="tai-lieu-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.tieuDe')}
                id="tai-lieu-tieuDe"
                name="tieuDe"
                data-cy="tieuDe"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.chiTiet')}
                id="tai-lieu-chiTiet"
                name="chiTiet"
                data-cy="chiTiet"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.tacGia')}
                id="tai-lieu-tacGia"
                name="tacGia"
                data-cy="tacGia"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.ngayDang')}
                id="tai-lieu-ngayDang"
                name="ngayDang"
                data-cy="ngayDang"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.priority')}
                id="tai-lieu-priority"
                name="priority"
                data-cy="priority"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.enable')}
                id="tai-lieu-enable"
                name="enable"
                data-cy="enable"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.hinhAnhDaiDien')}
                id="tai-lieu-hinhAnhDaiDien"
                name="hinhAnhDaiDien"
                data-cy="hinhAnhDaiDien"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.fileNoiDung')}
                id="tai-lieu-fileNoiDung"
                name="fileNoiDung"
                data-cy="fileNoiDung"
                type="text"
                validate={{
                  maxLength: { value: 1000, message: translate('entity.validation.maxlength', { max: 1000 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.taiLieu.loaiTaiLieu')}
                id="tai-lieu-loaiTaiLieu"
                data-cy="loaiTaiLieu"
                type="select"
                multiple
                name="loaiTaiLieus"
              >
                <option value="" key="0" />
                {loaiTaiLieus
                  ? loaiTaiLieus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.tenLoaiTaiLieu}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="tai-lieu-nhanSu"
                name="nhanSu"
                data-cy="nhanSu"
                label={translate('dcctApiApp.taiLieu.nhanSu')}
                type="select"
              >
                <option value="" key="0" />
                {nhanSus
                  ? nhanSus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/tai-lieu" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TaiLieuUpdate;
