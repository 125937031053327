export enum ChauLucEnum {
  CHAU_A = 'CHAU_A',

  CHAU_AU = 'CHAU_AU',

  CHAU_DAI_DUONG = 'CHAU_DAI_DUONG',

  CHAU_MY = 'CHAU_MY',

  CHAU_NAM_CUC = 'CHAU_NAM_CUC',

  CHAU_PHI = 'CHAU_PHI',

  CHAU_UC = 'CHAU_UC',
}
