import { ICongDoan } from 'app/shared/model/cong-doan.model';

export interface ILoaiCongDoan {
  id?: number;
  tenLoaiCongDoan?: string | null;
  priority?: number | null;
  congDoans?: ICongDoan[] | null;
}

export const defaultValue: Readonly<ILoaiCongDoan> = {};
