import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';

const EntitiesMenu = () => {
  return (
    <>
      {/* prettier-ignore */}
      <MenuItem icon="asterisk" to="/nhan-su">
        <Translate contentKey="global.menu.entities.nhanSu" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/chu-thanh">
        <Translate contentKey="global.menu.entities.chuThanh" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ten-thanh">
        <Translate contentKey="global.menu.entities.tenThanh" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/muc-vu">
        <Translate contentKey="global.menu.entities.mucVu" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/cong-doan">
        <Translate contentKey="global.menu.entities.congDoan" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/vung">
        <Translate contentKey="global.menu.entities.vung" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/loai-cong-doan">
        <Translate contentKey="global.menu.entities.loaiCongDoan" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/quoc-gia">
        <Translate contentKey="global.menu.entities.quocGia" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/tinh-trang">
        <Translate contentKey="global.menu.entities.tinhTrang" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/thiet-bi">
        <Translate contentKey="global.menu.entities.thietBi" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/loai-tin-tuc">
        <Translate contentKey="global.menu.entities.loaiTinTuc" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/thu-cha-giam-tinh">
        <Translate contentKey="global.menu.entities.thuChaGiamTinh" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/tin-tuc">
        <Translate contentKey="global.menu.entities.tinTuc" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/linh-dong">
        <Translate contentKey="global.menu.entities.linhDong" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/su-kien">
        <Translate contentKey="global.menu.entities.suKien" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/loai-tai-lieu">
        <Translate contentKey="global.menu.entities.loaiTaiLieu" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/tai-lieu">
        <Translate contentKey="global.menu.entities.taiLieu" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/thong-bao">
        <Translate contentKey="global.menu.entities.thongBao" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/phan-quyen-user">
        <Translate contentKey="global.menu.entities.phanQuyenUser" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/project-setting">
        <Translate contentKey="global.menu.entities.projectSetting" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/hinh-anh-tin-tuc">
        <Translate contentKey="global.menu.entities.hinhAnhTinTuc" />
      </MenuItem>
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
