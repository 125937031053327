import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LinhDong from './linh-dong';
import LinhDongDetail from './linh-dong-detail';
import LinhDongUpdate from './linh-dong-update';
import LinhDongDeleteDialog from './linh-dong-delete-dialog';

const LinhDongRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LinhDong />} />
    <Route path="new" element={<LinhDongUpdate />} />
    <Route path=":id">
      <Route index element={<LinhDongDetail />} />
      <Route path="edit" element={<LinhDongUpdate />} />
      <Route path="delete" element={<LinhDongDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LinhDongRoutes;
