import { INhanSu } from 'app/shared/model/nhan-su.model';
import { ThongBaoEnum } from 'app/shared/model/enumerations/thong-bao-enum.model';

export interface IThongBao {
  id?: number;
  tieuDe?: string | null;
  noiDung?: string | null;
  type?: ThongBaoEnum | null;
  idObject?: number | null;
  isRead?: boolean | null;
  nhanSu?: INhanSu | null;
}

export const defaultValue: Readonly<IThongBao> = {
  isRead: false,
};
