import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './ten-thanh.reducer';

export const TenThanhDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tenThanhEntity = useAppSelector(state => state.tenThanh.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tenThanhDetailsHeading">
          <Translate contentKey="dcctApiApp.tenThanh.detail.title">TenThanh</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{tenThanhEntity.id}</dd>
          <dt>
            <span id="tenThanh">
              <Translate contentKey="dcctApiApp.tenThanh.tenThanh">Ten Thanh</Translate>
            </span>
          </dt>
          <dd>{tenThanhEntity.tenThanh}</dd>
          <dt>
            <span id="ngayKinh">
              <Translate contentKey="dcctApiApp.tenThanh.ngayKinh">Ngay Kinh</Translate>
            </span>
          </dt>
          <dd>{tenThanhEntity.ngayKinh}</dd>
          <dt>
            <span id="gioiTinh">
              <Translate contentKey="dcctApiApp.tenThanh.gioiTinh">Gioi Tinh</Translate>
            </span>
          </dt>
          <dd>{tenThanhEntity.gioiTinh}</dd>
          <dt>
            <span id="hinhAnhViThanh">
              <Translate contentKey="dcctApiApp.tenThanh.hinhAnhViThanh">Hinh Anh Vi Thanh</Translate>
            </span>
          </dt>
          <dd>{tenThanhEntity.hinhAnhViThanh}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="dcctApiApp.tenThanh.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{tenThanhEntity.priority}</dd>
        </dl>
        <Button tag={Link} to="/ten-thanh" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ten-thanh/${tenThanhEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TenThanhDetail;
