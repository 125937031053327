import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import NhanSu from './nhan-su';
import NhanSuDetail from './nhan-su-detail';
import NhanSuUpdate from './nhan-su-update';
import NhanSuDeleteDialog from './nhan-su-delete-dialog';

const NhanSuRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<NhanSu />} />
    <Route path="new" element={<NhanSuUpdate />} />
    <Route path=":id">
      <Route index element={<NhanSuDetail />} />
      <Route path="edit" element={<NhanSuUpdate />} />
      <Route path="delete" element={<NhanSuDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default NhanSuRoutes;
