import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MucVu from './muc-vu';
import MucVuDetail from './muc-vu-detail';
import MucVuUpdate from './muc-vu-update';
import MucVuDeleteDialog from './muc-vu-delete-dialog';

const MucVuRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MucVu />} />
    <Route path="new" element={<MucVuUpdate />} />
    <Route path=":id">
      <Route index element={<MucVuDetail />} />
      <Route path="edit" element={<MucVuUpdate />} />
      <Route path="delete" element={<MucVuDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MucVuRoutes;
