import dayjs from 'dayjs';

export interface ILinhDong {
  id?: number;
  ngay?: string | null;
  tieuDe?: string | null;
  tieuDePhu?: string | null;
  mauAoLe?: string | null;
  ghiChu?: string | null;
  kinhThanh?: string | null;
  kinhSach?: string | null;
  kinhSang?: string | null;
  kinhTrua?: string | null;
  kinhChieu?: string | null;
  kinhToi?: string | null;
  hinhDaiDien?: string | null;
  kinh3h?: string | null;
  kinh6h?: string | null;
  kinh9h?: string | null;
}

export const defaultValue: Readonly<ILinhDong> = {};
