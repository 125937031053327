import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ILoaiTinTuc } from 'app/shared/model/loai-tin-tuc.model';
import { getEntities as getLoaiTinTucs } from 'app/entities/loai-tin-tuc/loai-tin-tuc.reducer';
import { ICongDoan } from 'app/shared/model/cong-doan.model';
import { getEntities as getCongDoans } from 'app/entities/cong-doan/cong-doan.reducer';
import { IThuChaGiamTinh } from 'app/shared/model/thu-cha-giam-tinh.model';
import { getEntities as getThuChaGiamTinhs } from 'app/entities/thu-cha-giam-tinh/thu-cha-giam-tinh.reducer';
import { INhanSu } from 'app/shared/model/nhan-su.model';
import { getEntities as getNhanSus } from 'app/entities/nhan-su/nhan-su.reducer';
import { ITinTuc } from 'app/shared/model/tin-tuc.model';
import { getEntity, updateEntity, createEntity, reset } from './tin-tuc.reducer';

export const TinTucUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const loaiTinTucs = useAppSelector(state => state.loaiTinTuc.entities);
  const congDoans = useAppSelector(state => state.congDoan.entities);
  const thuChaGiamTinhs = useAppSelector(state => state.thuChaGiamTinh.entities);
  const nhanSus = useAppSelector(state => state.nhanSu.entities);
  const tinTucEntity = useAppSelector(state => state.tinTuc.entity);
  const loading = useAppSelector(state => state.tinTuc.loading);
  const updating = useAppSelector(state => state.tinTuc.updating);
  const updateSuccess = useAppSelector(state => state.tinTuc.updateSuccess);

  const handleClose = () => {
    navigate('/tin-tuc' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getLoaiTinTucs({}));
    dispatch(getCongDoans({}));
    dispatch(getThuChaGiamTinhs({}));
    dispatch(getNhanSus({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.ngayDang = convertDateTimeToServer(values.ngayDang);

    const entity = {
      ...tinTucEntity,
      ...values,
      loaiTinTucs: mapIdList(values.loaiTinTucs),
      congDoans: mapIdList(values.congDoans),
      thuChaGiamTinhs: mapIdList(values.thuChaGiamTinhs),
      nhanSuDang: nhanSus.find(it => it.id.toString() === values.nhanSuDang.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          ngayDang: displayDefaultDateTime(),
        }
      : {
          ...tinTucEntity,
          ngayDang: convertDateTimeFromServer(tinTucEntity.ngayDang),
          loaiTinTucs: tinTucEntity?.loaiTinTucs?.map(e => e.id.toString()),
          congDoans: tinTucEntity?.congDoans?.map(e => e.id.toString()),
          thuChaGiamTinhs: tinTucEntity?.thuChaGiamTinhs?.map(e => e.id.toString()),
          nhanSuDang: tinTucEntity?.nhanSuDang?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dcctApiApp.tinTuc.home.createOrEditLabel" data-cy="TinTucCreateUpdateHeading">
            <Translate contentKey="dcctApiApp.tinTuc.home.createOrEditLabel">Create or edit a TinTuc</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="tin-tuc-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.ngayDang')}
                id="tin-tuc-ngayDang"
                name="ngayDang"
                data-cy="ngayDang"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.tieuDe')}
                id="tin-tuc-tieuDe"
                name="tieuDe"
                data-cy="tieuDe"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.detail')}
                id="tin-tuc-detail"
                name="detail"
                data-cy="detail"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.tacGia')}
                id="tin-tuc-tacGia"
                name="tacGia"
                data-cy="tacGia"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.hinhAnhDaiDien')}
                id="tin-tuc-hinhAnhDaiDien"
                name="hinhAnhDaiDien"
                data-cy="hinhAnhDaiDien"
                type="text"
                validate={{
                  maxLength: { value: 500, message: translate('entity.validation.maxlength', { max: 500 }) },
                }}
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.priority')}
                id="tin-tuc-priority"
                name="priority"
                data-cy="priority"
                type="text"
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.enable')}
                id="tin-tuc-enable"
                name="enable"
                data-cy="enable"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.choPhepGuiThongBao')}
                id="tin-tuc-choPhepGuiThongBao"
                name="choPhepGuiThongBao"
                data-cy="choPhepGuiThongBao"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.daGuiThongBao')}
                id="tin-tuc-daGuiThongBao"
                name="daGuiThongBao"
                data-cy="daGuiThongBao"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.loaiTinTuc')}
                id="tin-tuc-loaiTinTuc"
                data-cy="loaiTinTuc"
                type="select"
                multiple
                name="loaiTinTucs"
              >
                <option value="" key="0" />
                {loaiTinTucs
                  ? loaiTinTucs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.tenLoai}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.congDoan')}
                id="tin-tuc-congDoan"
                data-cy="congDoan"
                type="select"
                multiple
                name="congDoans"
              >
                <option value="" key="0" />
                {congDoans
                  ? congDoans.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.tenCongDoan}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                label={translate('dcctApiApp.tinTuc.thuChaGiamTinh')}
                id="tin-tuc-thuChaGiamTinh"
                data-cy="thuChaGiamTinh"
                type="select"
                multiple
                name="thuChaGiamTinhs"
              >
                <option value="" key="0" />
                {thuChaGiamTinhs
                  ? thuChaGiamTinhs.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.tieuDe}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="tin-tuc-nhanSuDang"
                name="nhanSuDang"
                data-cy="nhanSuDang"
                label={translate('dcctApiApp.tinTuc.nhanSuDang')}
                type="select"
              >
                <option value="" key="0" />
                {nhanSus
                  ? nhanSus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/tin-tuc" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TinTucUpdate;
