import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TenThanh from './ten-thanh';
import TenThanhDetail from './ten-thanh-detail';
import TenThanhUpdate from './ten-thanh-update';
import TenThanhDeleteDialog from './ten-thanh-delete-dialog';

const TenThanhRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TenThanh />} />
    <Route path="new" element={<TenThanhUpdate />} />
    <Route path=":id">
      <Route index element={<TenThanhDetail />} />
      <Route path="edit" element={<TenThanhUpdate />} />
      <Route path="delete" element={<TenThanhDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TenThanhRoutes;
