import { INhanSu } from 'app/shared/model/nhan-su.model';

export interface IChuThanh {
  id?: number;
  tenChucThanh?: string | null;
  priority?: number | null;
  tenGoiTac?: string | null;
  nhanSus?: INhanSu[] | null;
}

export const defaultValue: Readonly<IChuThanh> = {};
