import { INhanSu } from 'app/shared/model/nhan-su.model';

export interface IMucVu {
  id?: number;
  tenMucVu?: string | null;
  priority?: number | null;
  nhanSus?: INhanSu[] | null;
}

export const defaultValue: Readonly<IMucVu> = {};
