import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './nhan-su.reducer';

export const NhanSuDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const nhanSuEntity = useAppSelector(state => state.nhanSu.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="nhanSuDetailsHeading">
          <Translate contentKey="dcctApiApp.nhanSu.detail.title">NhanSu</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.id}</dd>
          <dt>
            <span id="ma">
              <Translate contentKey="dcctApiApp.nhanSu.ma">Ma</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.ma}</dd>
          <dt>
            <span id="avatar">
              <Translate contentKey="dcctApiApp.nhanSu.avatar">Avatar</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.avatar}</dd>
          <dt>
            <span id="diaChi">
              <Translate contentKey="dcctApiApp.nhanSu.diaChi">Dia Chi</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.diaChi}</dd>
          <dt>
            <span id="dienThoai">
              <Translate contentKey="dcctApiApp.nhanSu.dienThoai">Dien Thoai</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.dienThoai}</dd>
          <dt>
            <span id="ghiChu">
              <Translate contentKey="dcctApiApp.nhanSu.ghiChu">Ghi Chu</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.ghiChu}</dd>
          <dt>
            <span id="ngaySinh">
              <Translate contentKey="dcctApiApp.nhanSu.ngaySinh">Ngay Sinh</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.ngaySinh ? <TextFormat value={nhanSuEntity.ngaySinh} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="ngayKhan">
              <Translate contentKey="dcctApiApp.nhanSu.ngayKhan">Ngay Khan</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.ngayKhan ? <TextFormat value={nhanSuEntity.ngayKhan} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="ngayPhoTe">
              <Translate contentKey="dcctApiApp.nhanSu.ngayPhoTe">Ngay Pho Te</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.ngayPhoTe ? <TextFormat value={nhanSuEntity.ngayPhoTe} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="ngayLinhMuc">
              <Translate contentKey="dcctApiApp.nhanSu.ngayLinhMuc">Ngay Linh Muc</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.ngayLinhMuc ? <TextFormat value={nhanSuEntity.ngayLinhMuc} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="ngayChet">
              <Translate contentKey="dcctApiApp.nhanSu.ngayChet">Ngay Chet</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.ngayChet ? <TextFormat value={nhanSuEntity.ngayChet} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="hoTenBa">
              <Translate contentKey="dcctApiApp.nhanSu.hoTenBa">Ho Ten Ba</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.hoTenBa}</dd>
          <dt>
            <span id="ngayMatCuaBa">
              <Translate contentKey="dcctApiApp.nhanSu.ngayMatCuaBa">Ngay Mat Cua Ba</Translate>
            </span>
          </dt>
          <dd>
            {nhanSuEntity.ngayMatCuaBa ? <TextFormat value={nhanSuEntity.ngayMatCuaBa} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="hoTenMe">
              <Translate contentKey="dcctApiApp.nhanSu.hoTenMe">Ho Ten Me</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.hoTenMe}</dd>
          <dt>
            <span id="ngayMatCuaMe">
              <Translate contentKey="dcctApiApp.nhanSu.ngayMatCuaMe">Ngay Mat Cua Me</Translate>
            </span>
          </dt>
          <dd>
            {nhanSuEntity.ngayMatCuaMe ? <TextFormat value={nhanSuEntity.ngayMatCuaMe} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="otpResetMatKhau">
              <Translate contentKey="dcctApiApp.nhanSu.otpResetMatKhau">Otp Reset Mat Khau</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.otpResetMatKhau}</dd>
          <dt>
            <span id="chatId">
              <Translate contentKey="dcctApiApp.nhanSu.chatId">Chat Id</Translate>
            </span>
          </dt>
          <dd>{nhanSuEntity.chatId}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.user">User</Translate>
          </dt>
          <dd>{nhanSuEntity.user ? nhanSuEntity.user.login : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.chucThanh">Chuc Thanh</Translate>
          </dt>
          <dd>{nhanSuEntity.chucThanh ? nhanSuEntity.chucThanh.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.tenThanh">Ten Thanh</Translate>
          </dt>
          <dd>{nhanSuEntity.tenThanh ? nhanSuEntity.tenThanh.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.mucVu">Muc Vu</Translate>
          </dt>
          <dd>{nhanSuEntity.mucVu ? nhanSuEntity.mucVu.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.congDoan">Cong Doan</Translate>
          </dt>
          <dd>{nhanSuEntity.congDoan ? nhanSuEntity.congDoan.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.quocGia">Quoc Gia</Translate>
          </dt>
          <dd>{nhanSuEntity.quocGia ? nhanSuEntity.quocGia.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.tinhTrang">Tinh Trang</Translate>
          </dt>
          <dd>{nhanSuEntity.tinhTrang ? nhanSuEntity.tinhTrang.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.tenThanhCuaBa">Ten Thanh Cua Ba</Translate>
          </dt>
          <dd>{nhanSuEntity.tenThanhCuaBa ? nhanSuEntity.tenThanhCuaBa.id : ''}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.nhanSu.tenThanhCuaMe">Ten Thanh Cua Me</Translate>
          </dt>
          <dd>{nhanSuEntity.tenThanhCuaMe ? nhanSuEntity.tenThanhCuaMe.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/nhan-su" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/nhan-su/${nhanSuEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default NhanSuDetail;
