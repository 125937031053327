import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ThongBao from './thong-bao';
import ThongBaoDetail from './thong-bao-detail';
import ThongBaoUpdate from './thong-bao-update';
import ThongBaoDeleteDialog from './thong-bao-delete-dialog';

const ThongBaoRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ThongBao />} />
    <Route path="new" element={<ThongBaoUpdate />} />
    <Route path=":id">
      <Route index element={<ThongBaoDetail />} />
      <Route path="edit" element={<ThongBaoUpdate />} />
      <Route path="delete" element={<ThongBaoDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ThongBaoRoutes;
