import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LoaiTinTuc from './loai-tin-tuc';
import LoaiTinTucDetail from './loai-tin-tuc-detail';
import LoaiTinTucUpdate from './loai-tin-tuc-update';
import LoaiTinTucDeleteDialog from './loai-tin-tuc-delete-dialog';

const LoaiTinTucRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LoaiTinTuc />} />
    <Route path="new" element={<LoaiTinTucUpdate />} />
    <Route path=":id">
      <Route index element={<LoaiTinTucDetail />} />
      <Route path="edit" element={<LoaiTinTucUpdate />} />
      <Route path="delete" element={<LoaiTinTucDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LoaiTinTucRoutes;
