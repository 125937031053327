import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './tin-tuc.reducer';

export const TinTucDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tinTucEntity = useAppSelector(state => state.tinTuc.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tinTucDetailsHeading">
          <Translate contentKey="dcctApiApp.tinTuc.detail.title">TinTuc</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.id}</dd>
          <dt>
            <span id="ngayDang">
              <Translate contentKey="dcctApiApp.tinTuc.ngayDang">Ngay Dang</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.ngayDang ? <TextFormat value={tinTucEntity.ngayDang} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="tieuDe">
              <Translate contentKey="dcctApiApp.tinTuc.tieuDe">Tieu De</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.tieuDe}</dd>
          <dt>
            <span id="detail">
              <Translate contentKey="dcctApiApp.tinTuc.detail">Detail</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.detail}</dd>
          <dt>
            <span id="tacGia">
              <Translate contentKey="dcctApiApp.tinTuc.tacGia">Tac Gia</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.tacGia}</dd>
          <dt>
            <span id="hinhAnhDaiDien">
              <Translate contentKey="dcctApiApp.tinTuc.hinhAnhDaiDien">Hinh Anh Dai Dien</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.hinhAnhDaiDien}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="dcctApiApp.tinTuc.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.priority}</dd>
          <dt>
            <span id="enable">
              <Translate contentKey="dcctApiApp.tinTuc.enable">Enable</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.enable ? 'true' : 'false'}</dd>
          <dt>
            <span id="choPhepGuiThongBao">
              <Translate contentKey="dcctApiApp.tinTuc.choPhepGuiThongBao">Cho Phep Gui Thong Bao</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.choPhepGuiThongBao ? 'true' : 'false'}</dd>
          <dt>
            <span id="daGuiThongBao">
              <Translate contentKey="dcctApiApp.tinTuc.daGuiThongBao">Da Gui Thong Bao</Translate>
            </span>
          </dt>
          <dd>{tinTucEntity.daGuiThongBao ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.tinTuc.loaiTinTuc">Loai Tin Tuc</Translate>
          </dt>
          <dd>
            {tinTucEntity.loaiTinTucs
              ? tinTucEntity.loaiTinTucs.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.tenLoai}</a>
                    {tinTucEntity.loaiTinTucs && i === tinTucEntity.loaiTinTucs.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="dcctApiApp.tinTuc.congDoan">Cong Doan</Translate>
          </dt>
          <dd>
            {tinTucEntity.congDoans
              ? tinTucEntity.congDoans.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.tenCongDoan}</a>
                    {tinTucEntity.congDoans && i === tinTucEntity.congDoans.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="dcctApiApp.tinTuc.thuChaGiamTinh">Thu Cha Giam Tinh</Translate>
          </dt>
          <dd>
            {tinTucEntity.thuChaGiamTinhs
              ? tinTucEntity.thuChaGiamTinhs.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.tieuDe}</a>
                    {tinTucEntity.thuChaGiamTinhs && i === tinTucEntity.thuChaGiamTinhs.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="dcctApiApp.tinTuc.nhanSuDang">Nhan Su Dang</Translate>
          </dt>
          <dd>{tinTucEntity.nhanSuDang ? tinTucEntity.nhanSuDang.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/tin-tuc" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/tin-tuc/${tinTucEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TinTucDetail;
