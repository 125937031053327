import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TinTuc from './tin-tuc';
import TinTucDetail from './tin-tuc-detail';
import TinTucUpdate from './tin-tuc-update';
import TinTucDeleteDialog from './tin-tuc-delete-dialog';

const TinTucRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TinTuc />} />
    <Route path="new" element={<TinTucUpdate />} />
    <Route path=":id">
      <Route index element={<TinTucDetail />} />
      <Route path="edit" element={<TinTucUpdate />} />
      <Route path="delete" element={<TinTucDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TinTucRoutes;
