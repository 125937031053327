import { INhanSu } from 'app/shared/model/nhan-su.model';
import { ChauLucEnum } from 'app/shared/model/enumerations/chau-luc-enum.model';

export interface IQuocGia {
  id?: number;
  tenQuocGia?: string | null;
  chauLuc?: ChauLucEnum | null;
  priority?: number | null;
  nhanSus?: INhanSu[] | null;
}

export const defaultValue: Readonly<IQuocGia> = {};
