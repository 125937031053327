import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CongDoan from './cong-doan';
import CongDoanDetail from './cong-doan-detail';
import CongDoanUpdate from './cong-doan-update';
import CongDoanDeleteDialog from './cong-doan-delete-dialog';

const CongDoanRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CongDoan />} />
    <Route path="new" element={<CongDoanUpdate />} />
    <Route path=":id">
      <Route index element={<CongDoanDetail />} />
      <Route path="edit" element={<CongDoanUpdate />} />
      <Route path="delete" element={<CongDoanDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CongDoanRoutes;
