import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HinhAnhTinTuc from './hinh-anh-tin-tuc';
import HinhAnhTinTucDetail from './hinh-anh-tin-tuc-detail';
import HinhAnhTinTucUpdate from './hinh-anh-tin-tuc-update';
import HinhAnhTinTucDeleteDialog from './hinh-anh-tin-tuc-delete-dialog';

const HinhAnhTinTucRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HinhAnhTinTuc />} />
    <Route path="new" element={<HinhAnhTinTucUpdate />} />
    <Route path=":id">
      <Route index element={<HinhAnhTinTucDetail />} />
      <Route path="edit" element={<HinhAnhTinTucUpdate />} />
      <Route path="delete" element={<HinhAnhTinTucDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HinhAnhTinTucRoutes;
