import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './tai-lieu.reducer';

export const TaiLieuDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const taiLieuEntity = useAppSelector(state => state.taiLieu.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="taiLieuDetailsHeading">
          <Translate contentKey="dcctApiApp.taiLieu.detail.title">TaiLieu</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.id}</dd>
          <dt>
            <span id="tieuDe">
              <Translate contentKey="dcctApiApp.taiLieu.tieuDe">Tieu De</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.tieuDe}</dd>
          <dt>
            <span id="chiTiet">
              <Translate contentKey="dcctApiApp.taiLieu.chiTiet">Chi Tiet</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.chiTiet}</dd>
          <dt>
            <span id="tacGia">
              <Translate contentKey="dcctApiApp.taiLieu.tacGia">Tac Gia</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.tacGia}</dd>
          <dt>
            <span id="ngayDang">
              <Translate contentKey="dcctApiApp.taiLieu.ngayDang">Ngay Dang</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.ngayDang ? <TextFormat value={taiLieuEntity.ngayDang} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="priority">
              <Translate contentKey="dcctApiApp.taiLieu.priority">Priority</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.priority}</dd>
          <dt>
            <span id="enable">
              <Translate contentKey="dcctApiApp.taiLieu.enable">Enable</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.enable ? 'true' : 'false'}</dd>
          <dt>
            <span id="hinhAnhDaiDien">
              <Translate contentKey="dcctApiApp.taiLieu.hinhAnhDaiDien">Hinh Anh Dai Dien</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.hinhAnhDaiDien}</dd>
          <dt>
            <span id="fileNoiDung">
              <Translate contentKey="dcctApiApp.taiLieu.fileNoiDung">File Noi Dung</Translate>
            </span>
          </dt>
          <dd>{taiLieuEntity.fileNoiDung}</dd>
          <dt>
            <Translate contentKey="dcctApiApp.taiLieu.loaiTaiLieu">Loai Tai Lieu</Translate>
          </dt>
          <dd>
            {taiLieuEntity.loaiTaiLieus
              ? taiLieuEntity.loaiTaiLieus.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.tenLoaiTaiLieu}</a>
                    {taiLieuEntity.loaiTaiLieus && i === taiLieuEntity.loaiTaiLieus.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
          <dt>
            <Translate contentKey="dcctApiApp.taiLieu.nhanSu">Nhan Su</Translate>
          </dt>
          <dd>{taiLieuEntity.nhanSu ? taiLieuEntity.nhanSu.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/tai-lieu" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/tai-lieu/${taiLieuEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default TaiLieuDetail;
