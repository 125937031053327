import dayjs from 'dayjs';
import { ILoaiTinTuc } from 'app/shared/model/loai-tin-tuc.model';
import { ICongDoan } from 'app/shared/model/cong-doan.model';
import { IThuChaGiamTinh } from 'app/shared/model/thu-cha-giam-tinh.model';
import { INhanSu } from 'app/shared/model/nhan-su.model';

export interface ITinTuc {
  id?: number;
  ngayDang?: string | null;
  tieuDe?: string | null;
  detail?: string | null;
  tacGia?: string | null;
  hinhAnhDaiDien?: string | null;
  priority?: number | null;
  enable?: boolean | null;
  choPhepGuiThongBao?: boolean | null;
  daGuiThongBao?: boolean | null;
  loaiTinTucs?: ILoaiTinTuc[] | null;
  congDoans?: ICongDoan[] | null;
  thuChaGiamTinhs?: IThuChaGiamTinh[] | null;
  nhanSuDang?: INhanSu | null;
}

export const defaultValue: Readonly<ITinTuc> = {
  enable: false,
  choPhepGuiThongBao: false,
  daGuiThongBao: false,
};
