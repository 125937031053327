import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProjectSetting from './project-setting';
import ProjectSettingDetail from './project-setting-detail';
import ProjectSettingUpdate from './project-setting-update';
import ProjectSettingDeleteDialog from './project-setting-delete-dialog';

const ProjectSettingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProjectSetting />} />
    <Route path="new" element={<ProjectSettingUpdate />} />
    <Route path=":id">
      <Route index element={<ProjectSettingDetail />} />
      <Route path="edit" element={<ProjectSettingUpdate />} />
      <Route path="delete" element={<ProjectSettingDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProjectSettingRoutes;
