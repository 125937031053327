import { ITaiLieu } from 'app/shared/model/tai-lieu.model';

export interface ILoaiTaiLieu {
  id?: number;
  tenLoaiTaiLieu?: string | null;
  hinhAnhDaiDien?: string | null;
  priority?: number | null;
  taiLieus?: ITaiLieu[] | null;
}

export const defaultValue: Readonly<ILoaiTaiLieu> = {};
