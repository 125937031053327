import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SuKien from './su-kien';
import SuKienDetail from './su-kien-detail';
import SuKienUpdate from './su-kien-update';
import SuKienDeleteDialog from './su-kien-delete-dialog';

const SuKienRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SuKien />} />
    <Route path="new" element={<SuKienUpdate />} />
    <Route path=":id">
      <Route index element={<SuKienDetail />} />
      <Route path="edit" element={<SuKienUpdate />} />
      <Route path="delete" element={<SuKienDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SuKienRoutes;
