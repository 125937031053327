import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TinhTrang from './tinh-trang';
import TinhTrangDetail from './tinh-trang-detail';
import TinhTrangUpdate from './tinh-trang-update';
import TinhTrangDeleteDialog from './tinh-trang-delete-dialog';

const TinhTrangRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TinhTrang />} />
    <Route path="new" element={<TinhTrangUpdate />} />
    <Route path=":id">
      <Route index element={<TinhTrangDetail />} />
      <Route path="edit" element={<TinhTrangUpdate />} />
      <Route path="delete" element={<TinhTrangDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TinhTrangRoutes;
