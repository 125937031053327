import { ITinTuc } from 'app/shared/model/tin-tuc.model';

export interface ILoaiTinTuc {
  id?: number;
  tenLoai?: string | null;
  hinhDaiDien?: string | null;
  priority?: number | null;
  loaiTinTucCha?: ILoaiTinTuc | null;
  tinTucs?: ITinTuc[] | null;
}

export const defaultValue: Readonly<ILoaiTinTuc> = {};
