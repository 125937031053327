import nhanSu from 'app/entities/nhan-su/nhan-su.reducer';
import chuThanh from 'app/entities/chu-thanh/chu-thanh.reducer';
import tenThanh from 'app/entities/ten-thanh/ten-thanh.reducer';
import mucVu from 'app/entities/muc-vu/muc-vu.reducer';
import congDoan from 'app/entities/cong-doan/cong-doan.reducer';
import vung from 'app/entities/vung/vung.reducer';
import loaiCongDoan from 'app/entities/loai-cong-doan/loai-cong-doan.reducer';
import quocGia from 'app/entities/quoc-gia/quoc-gia.reducer';
import tinhTrang from 'app/entities/tinh-trang/tinh-trang.reducer';
import thietBi from 'app/entities/thiet-bi/thiet-bi.reducer';
import loaiTinTuc from 'app/entities/loai-tin-tuc/loai-tin-tuc.reducer';
import thuChaGiamTinh from 'app/entities/thu-cha-giam-tinh/thu-cha-giam-tinh.reducer';
import tinTuc from 'app/entities/tin-tuc/tin-tuc.reducer';
import linhDong from 'app/entities/linh-dong/linh-dong.reducer';
import suKien from 'app/entities/su-kien/su-kien.reducer';
import loaiTaiLieu from 'app/entities/loai-tai-lieu/loai-tai-lieu.reducer';
import taiLieu from 'app/entities/tai-lieu/tai-lieu.reducer';
import thongBao from 'app/entities/thong-bao/thong-bao.reducer';
import phanQuyenUser from 'app/entities/phan-quyen-user/phan-quyen-user.reducer';
import projectSetting from 'app/entities/project-setting/project-setting.reducer';
import hinhAnhTinTuc from 'app/entities/hinh-anh-tin-tuc/hinh-anh-tin-tuc.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  nhanSu,
  chuThanh,
  tenThanh,
  mucVu,
  congDoan,
  vung,
  loaiCongDoan,
  quocGia,
  tinhTrang,
  thietBi,
  loaiTinTuc,
  thuChaGiamTinh,
  tinTuc,
  linhDong,
  suKien,
  loaiTaiLieu,
  taiLieu,
  thongBao,
  phanQuyenUser,
  projectSetting,
  hinhAnhTinTuc,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
