import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import LoaiCongDoan from './loai-cong-doan';
import LoaiCongDoanDetail from './loai-cong-doan-detail';
import LoaiCongDoanUpdate from './loai-cong-doan-update';
import LoaiCongDoanDeleteDialog from './loai-cong-doan-delete-dialog';

const LoaiCongDoanRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<LoaiCongDoan />} />
    <Route path="new" element={<LoaiCongDoanUpdate />} />
    <Route path=":id">
      <Route index element={<LoaiCongDoanDetail />} />
      <Route path="edit" element={<LoaiCongDoanUpdate />} />
      <Route path="delete" element={<LoaiCongDoanDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default LoaiCongDoanRoutes;
