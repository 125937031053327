import { INhanSu } from 'app/shared/model/nhan-su.model';
import { GioiTinhEnum } from 'app/shared/model/enumerations/gioi-tinh-enum.model';

export interface ITenThanh {
  id?: number;
  tenThanh?: string | null;
  ngayKinh?: string | null;
  gioiTinh?: GioiTinhEnum | null;
  hinhAnhViThanh?: string | null;
  priority?: number | null;
  nhanSus?: INhanSu[] | null;
}

export const defaultValue: Readonly<ITenThanh> = {};
