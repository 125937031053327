import { INhanSu } from 'app/shared/model/nhan-su.model';
import { PhanQuyenUserTypeEnum } from 'app/shared/model/enumerations/phan-quyen-user-type-enum.model';

export interface IPhanQuyenUser {
  id?: number;
  objectId?: number | null;
  type?: PhanQuyenUserTypeEnum | null;
  nhanSu?: INhanSu | null;
}

export const defaultValue: Readonly<IPhanQuyenUser> = {};
