import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ThuChaGiamTinh from './thu-cha-giam-tinh';
import ThuChaGiamTinhDetail from './thu-cha-giam-tinh-detail';
import ThuChaGiamTinhUpdate from './thu-cha-giam-tinh-update';
import ThuChaGiamTinhDeleteDialog from './thu-cha-giam-tinh-delete-dialog';

const ThuChaGiamTinhRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ThuChaGiamTinh />} />
    <Route path="new" element={<ThuChaGiamTinhUpdate />} />
    <Route path=":id">
      <Route index element={<ThuChaGiamTinhDetail />} />
      <Route path="edit" element={<ThuChaGiamTinhUpdate />} />
      <Route path="delete" element={<ThuChaGiamTinhDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ThuChaGiamTinhRoutes;
