import dayjs from 'dayjs';
import { IVung } from 'app/shared/model/vung.model';
import { ILoaiCongDoan } from 'app/shared/model/loai-cong-doan.model';
import { INhanSu } from 'app/shared/model/nhan-su.model';
import { ITinTuc } from 'app/shared/model/tin-tuc.model';

export interface ICongDoan {
  id?: number;
  tenCongDoan?: string | null;
  ngayThanhLap?: string | null;
  ngayBonMang?: string | null;
  tenThanhBon?: string | null;
  diaChi?: string | null;
  hinhAnhDaiDien?: string | null;
  priority?: number | null;
  nhomChatUid?: string | null;
  vung?: IVung | null;
  loaiCongDoan?: ILoaiCongDoan | null;
  chaBeTren?: INhanSu | null;
  tinTucs?: ITinTuc[] | null;
  nhanSus?: INhanSu[] | null;
}

export const defaultValue: Readonly<ICongDoan> = {};
